<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
    <fw-panel-box
      title="Notificações de gestão"
      icon="notification-on"
      :height="unreadNotificationsCounter > 0 ? 'sm' : '2xs'"
      :counter="unreadNotificationsCounter"
    >
      <template #default>
        <PanelNotificationList
          type="minimal"
          :show-header="false"
          :app="'myuc'"
          filter-type="unread"
          :tags="['management']"
          paddingless
          :limit="25"
          @unread-count="unreadNotificationsCounter = $event"
        />
      </template>
    </fw-panel-box>

    <fw-panel-box
      title="Ações pendentes"
      :counter="pendingActions?.data?.length"
      :counter-total="pendingActions?.pagination?.total_items"
      icon="actions"
      :height="pendingActions?.pagination?.total_items ? 'sm' : '2xs'"
      :loading="loading.pendingActions"
    >
      <template #default>
        <div v-if="!pendingActions?.data?.length" class="flex items-center justify-center text-sm text-gray-500 h-full">
          Não existem ações pendentes.
        </div>

        <template v-else>
          <b-tabs v-model="activeTab.pendingActions" class="-mt-2">
            <b-tab-item label="Mapas de férias" value="pending-maps">
              <RecordManageMapUser
                v-for="item in pendingActions?.data"
                :key="item.context_key"
                :item="item.details"
                show-map-meta
                :user="pendingActions?.users?.[item.details.user_key]"
                class="mb-1 border-b"
              />
            </b-tab-item>
          </b-tabs>
        </template>
      </template>
    </fw-panel-box>
  </div>
</template>

<script>
import RecordManageMapUser from '@/components/records/manage/RecordManageMapUser'
import PanelNotificationList from '@/fw-modules/fw-core-vue/notifications/components/panels/PanelNotificationList'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    RecordManageMapUser,
    PanelNotificationList
  },

  props: {
    year: {
      type: Number
    }
  },

  data() {
    return {
      unreadNotificationsCounter: 0,
      stats: [],
      weeks: {},
      pendingActions: {},
      loading: {
        pendingActions: true
      },
      activeTab: {
        pendingActions: 'pending-maps'
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    }
  },

  mounted() {
    this.getPendingActions()
  },

  methods: {
    async getPendingActions() {
      this.loading.pendingActions = true

      await utils.tryAndCatch(this, async () => {
        const res = await this.api.getPendingActions({
          limit: 10,
          page: 1,
          order: 'created_at',
          direction: 'desc',
          context: 'map_user',
          year: this.year
        })
        this.pendingActions = res
      })

      this.loading.pendingActions = false
    }
  }
}
</script>
